.actorHoshuKeisan .react-bootstrap-table table > tbody > tr > td:nth-child(2)
{ position: sticky; position: -webkit-sticky; left:0px; z-index:1; }

.actorHoshuKeisan .react-bootstrap-table table > tbody > tr > td:nth-child(3)
{ background-color: rgba(255,255,255,.6); position: sticky; position: -webkit-sticky; left:50px; z-index:1; }

.actorHoshuKeisan .react-bootstrap-table table > tbody > tr > td:nth-child(4)
{ background-color: rgba(255,255,255,.6); position: sticky; position: -webkit-sticky; left:110px; z-index:1; }

.actorHoshuKeisan .react-bootstrap-table {
  height: 80vh;
  overflow-y: scroll;
}

.actorHoshuKeisan .react-bootstrap-table table > thead > tr > th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: rgba(255,255,255,.6);
  z-index:10;
}

.actorHoshuKeisan2 .react-bootstrap-table table > tbody > tr > td:nth-child(1)
{ background-color: rgba(255,255,255,.6); position: sticky; position: -webkit-sticky; left:0px; z-index:1; }



.actorMonthlyScore .react-bootstrap-table table > tbody > tr > td:nth-child(1)
{ position: sticky; position: -webkit-sticky; left:0px; z-index:1; }

.actorMonthlyScore .react-bootstrap-table table > tbody > tr > td:nth-child(2)
{ background-color: rgba(255,255,255,.6); position: sticky; position: -webkit-sticky; left:50px; z-index:1; }

.actorMonthlyScore .react-bootstrap-table table > tbody > tr > td:nth-child(3)
{ background-color: rgba(255,255,255,.6); position: sticky; position: -webkit-sticky; left:110px; z-index:1; }

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(1) {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(2) {
  background-color: rgba(255, 255, 255, .6);
  position: sticky;
  position: -webkit-sticky;
  left: 50px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(3) {
  background-color: rgba(255, 255, 255, .6);
  position: sticky;
  position: -webkit-sticky;
  left: 100px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(4) {
  background-color: rgba(255, 255, 255, .6);
  position: sticky;
  position: -webkit-sticky;
  left: 150px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(5) {
  background-color: rgba(255, 255, 255, .6);
  position: sticky;
  position: -webkit-sticky;
  left: 210px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(6) {
  background-color: rgba(255, 255, 255, .6);
  position: sticky;
  position: -webkit-sticky;
  left: 285px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table table>tbody>tr>td:nth-child(7) {
  background-color: rgba(255, 255, 255, .6);
  position: sticky;
  position: -webkit-sticky;
  left: 340px;
  z-index: 1;
}

.customerManagement .react-bootstrap-table {
  height: 80vh;
  overflow-y: scroll;
}

.customerManagement .react-bootstrap-table table>thead>tr>th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: rgba(255, 255, 255, .6);
  z-index: 10;
}
/* スクロールバー */
.scrollbar {
  width: 100%;
  height: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.scrollbar .inner {
  height: 10px;
}
.scrollbarY {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.scrollbarY .inner {
  height: 90%;
}
.bottonStyle {
  background-color: transparent;
  font-size: 800%;
  font-weight:600;
  position: absolute;
  left: 92%;
  top: -1%;
  color:#FFFFFF;
}
/* スクロールバーのスタイル */
.scrollbar::-webkit-scrollbar,
.react-bootstrap-table::-webkit-scrollbar {
  height: 10px;
}
.scrollbar::-webkit-scrollbar-track,
.react-bootstrap-table::-webkit-scrollbar-track {
  border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-thumb,
.react-bootstrap-table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e7e7e7;
}
/* スクロールバーのスタイル */
.scrollbarY::-webkit-scrollbar,
.react-bootstrap-table::-webkit-scrollbar {
  width: 10px;
}
.scrollbarY::-webkit-scrollbar-track,
.react-bootstrap-table::-webkit-scrollbar-track {
  border-radius: 5px;
}
.scrollbarY::-webkit-scrollbar-thumb,
.react-bootstrap-table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e7e7e7;
}
.gift-notice{
  background-color: #ff0000;
  width: 35%;
  height: 50%;
  border-radius: 10px;
  position: absolute;
  right: 2%;
  top: -25%;
  text-align: center;
}
.gift-notice-font{
  font-size : small;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  top: -10%;
  left:10%;
}
.button-like {
  border: 1px solid rgb(47, 53, 58);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.25rem;
}

.inline-spinner {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.cursor-pointer {
  cursor: pointer;
}

.cell-color-future {
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-future:hover {
  white-space: nowrap;
  cursor: pointer;
  background-color: #DADADA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-future-event {
  white-space: nowrap;
  cursor: pointer;
  background-color: #F2F2FF;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-future-event:hover {
  white-space: nowrap;
  cursor: pointer;
  background-color: #DADAEA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-past {
  white-space: nowrap;
  background-color: #EAEAEA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-past-event {
  white-space: nowrap;
  background-color: #EAEAFA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.shift-cell {
  text-align: center;
}

.godo-eigyo-hukutenpo-info-box {
  background-color: #fff;
  text-align: center;
  padding: 2em;
  width: 22em;
  font-size: 125%;
  border: 1px #999 solid;
  border-radius: 6px;
  margin: 3em auto;
}

.denpyo-detail-wariai-area .card-body .row .col {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
}
.denpyo-detail-wariai-area .card-body .row .col p.content {
  font-size: 120%;
}

p.list-empty {
  border: 1px #ccc solid;
  border-radius: 4px;
  padding: 2em;
  text-align: center;
  background-color: #f3f3f3;
  width: 92%;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.text-disabled {
  color: #777;
}
